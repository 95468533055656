import * as React from "react"

export default function Cta() {
    return (

        <svg width="100%" height="100%" viewBox="0 0 1456 713" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M472.383 531.42C472.107 531.428 471.838 531.349 471.625 531.198L365.303 455.122L1.54644 528.156C1.24513 528.215 0.929033 528.168 0.667684 528.026C0.406335 527.884 0.221143 527.659 0.152847 527.399C0.0845505 527.139 0.138745 526.867 0.303508 526.641C0.468272 526.416 0.730107 526.257 1.03141 526.198L365.334 453.079C365.505 453.044 365.683 453.045 365.854 453.08C366.025 453.115 366.184 453.184 366.318 453.281L473.11 529.664C473.225 529.746 473.32 529.847 473.389 529.961C473.459 530.074 473.502 530.199 473.515 530.327C473.529 530.455 473.512 530.584 473.467 530.707C473.422 530.83 473.35 530.943 473.254 531.042C473.151 531.158 473.02 531.252 472.869 531.318C472.718 531.383 472.552 531.418 472.383 531.42Z" fill="#72B094" />
            <path d="M496.619 594.093C496.352 594.093 496.094 594.012 495.889 593.863C495.685 593.715 495.548 593.51 495.501 593.283C495.455 593.056 495.503 592.823 495.637 592.623C495.771 592.424 495.982 592.272 496.233 592.193L859.02 479.486V411.786L649.619 379.307L289.474 437.247V469.563C289.474 469.823 289.355 470.072 289.142 470.255C288.929 470.439 288.64 470.542 288.338 470.542C288.037 470.542 287.748 470.439 287.535 470.255C287.322 470.072 287.202 469.823 287.202 469.563V436.431C287.203 436.203 287.297 435.982 287.467 435.806C287.637 435.631 287.873 435.513 288.134 435.471L649.406 377.368C649.542 377.345 649.68 377.345 649.815 377.368L860.369 410.01C860.63 410.053 860.866 410.173 861.036 410.349C861.206 410.525 861.299 410.747 861.3 410.977V480.178C861.299 480.38 861.226 480.577 861.091 480.742C860.955 480.907 860.764 481.031 860.543 481.099L496.998 594.041C496.876 594.076 496.748 594.094 496.619 594.093Z" fill="#72B094" />
            <path d="M496.619 499.431C496.493 499.43 496.368 499.413 496.248 499.379L378.089 464.177C377.803 464.091 377.57 463.91 377.439 463.675C377.308 463.44 377.291 463.17 377.392 462.924C377.492 462.678 377.702 462.477 377.975 462.364C378.247 462.251 378.561 462.237 378.846 462.323L496.657 497.407L859.862 410.03C860.007 409.992 860.16 409.979 860.311 409.992C860.462 410.005 860.609 410.044 860.742 410.107C860.876 410.17 860.993 410.255 861.087 410.358C861.182 410.461 861.251 410.579 861.292 410.705C861.332 410.831 861.343 410.963 861.323 411.093C861.304 411.223 861.254 411.348 861.177 411.461C861.1 411.574 860.998 411.673 860.876 411.751C860.754 411.83 860.615 411.886 860.467 411.917L496.922 499.398C496.823 499.419 496.722 499.43 496.619 499.431Z" fill="#72B094" />
            <path d="M354.806 457.204C354.68 457.203 354.554 457.185 354.434 457.152L287.959 437.358C287.818 437.315 287.688 437.248 287.576 437.162C287.465 437.076 287.374 436.972 287.309 436.855C287.245 436.739 287.207 436.613 287.199 436.484C287.191 436.355 287.212 436.226 287.262 436.104C287.312 435.982 287.389 435.87 287.489 435.774C287.589 435.678 287.71 435.6 287.845 435.544C287.98 435.488 288.126 435.456 288.276 435.449C288.425 435.442 288.575 435.461 288.716 435.504L355.184 455.298C355.44 455.373 355.656 455.523 355.794 455.723C355.932 455.922 355.983 456.158 355.939 456.387C355.894 456.616 355.756 456.824 355.55 456.974C355.344 457.124 355.083 457.205 354.813 457.204H354.806Z" fill="#72B094" />
            <path d="M192.15 489.847C191.848 489.847 191.559 489.744 191.346 489.56C191.133 489.376 191.014 489.127 191.014 488.867V459.281C191.014 459.021 191.133 458.772 191.346 458.588C191.559 458.404 191.848 458.301 192.15 458.301C192.451 458.301 192.74 458.404 192.953 458.588C193.166 458.772 193.286 459.021 193.286 459.281V488.867C193.286 489.127 193.166 489.376 192.953 489.56C192.74 489.744 192.451 489.847 192.15 489.847Z" fill="#72B094" />
            <path d="M351.958 427.181C351.657 427.181 351.368 427.077 351.155 426.894C350.942 426.71 350.822 426.461 350.822 426.201V395.955C350.824 395.724 350.919 395.5 351.092 395.324C351.265 395.147 351.505 395.029 351.769 394.989L573.683 362.347C573.984 362.303 574.293 362.365 574.542 362.518C574.79 362.671 574.958 362.903 575.008 363.163C575.059 363.422 574.987 363.689 574.809 363.903C574.632 364.117 574.363 364.262 574.062 364.305L353.094 396.784V426.201C353.094 426.33 353.065 426.457 353.008 426.576C352.951 426.695 352.867 426.803 352.762 426.894C352.656 426.985 352.531 427.057 352.393 427.106C352.255 427.155 352.108 427.181 351.958 427.181Z" fill="#72B094" />
            <path d="M432.703 414.195C432.606 414.195 432.509 414.181 432.416 414.156L351.663 395.629C351.519 395.595 351.384 395.537 351.265 395.459C351.147 395.381 351.048 395.283 350.973 395.171C350.899 395.06 350.85 394.937 350.831 394.809C350.812 394.681 350.822 394.552 350.86 394.427C350.899 394.303 350.966 394.186 351.057 394.084C351.148 393.982 351.261 393.897 351.391 393.833C351.52 393.768 351.663 393.727 351.811 393.71C351.959 393.693 352.11 393.702 352.254 393.735L432.999 412.27C433.143 412.302 433.278 412.359 433.396 412.437C433.515 412.515 433.614 412.612 433.689 412.723C433.763 412.834 433.812 412.957 433.831 413.084C433.85 413.211 433.84 413.34 433.802 413.464C433.738 413.674 433.596 413.859 433.397 413.991C433.198 414.123 432.954 414.195 432.703 414.195Z" fill="#72B094" />
            <path d="M950.292 404.115H950.088L575.008 344.256L575.765 390.719C575.767 390.847 575.74 390.975 575.684 391.094C575.629 391.214 575.547 391.323 575.443 391.415C575.339 391.507 575.215 391.581 575.078 391.631C574.941 391.682 574.793 391.709 574.644 391.711C574.346 391.711 574.059 391.61 573.846 391.429C573.634 391.248 573.512 391.002 573.508 390.745L572.751 343.087C572.747 343.037 572.747 342.987 572.751 342.937C572.747 342.891 572.747 342.845 572.751 342.8C572.809 342.619 572.924 342.456 573.084 342.33C573.145 342.283 573.21 342.241 573.281 342.206L687.646 289.325C687.857 289.227 688.098 289.189 688.335 289.214L722.418 293.131L919.338 315.981C919.512 316.002 919.678 316.058 919.823 316.144L1029.64 380.123C1029.82 380.225 1029.96 380.368 1030.05 380.536C1030.13 380.703 1030.16 380.888 1030.13 381.07C1030.11 381.252 1030.02 381.422 1029.88 381.563C1029.74 381.704 1029.57 381.809 1029.36 381.866L950.595 404.063C950.497 404.092 950.395 404.11 950.292 404.115ZM577.28 342.617L950.216 402.156L1026.5 380.613L918.861 317.9L722.168 295.09L688.449 291.212L577.28 342.617Z" fill="#72B094" />
            <path d="M950.294 483.763C949.992 483.763 949.703 483.659 949.49 483.476C949.277 483.292 949.158 483.043 949.158 482.783V403.286L918.158 317.287C918.104 317.162 918.08 317.029 918.087 316.896C918.095 316.763 918.134 316.633 918.202 316.513C918.27 316.394 918.365 316.287 918.483 316.2C918.6 316.114 918.737 316.049 918.884 316.009C919.032 315.969 919.187 315.956 919.341 315.97C919.495 315.984 919.644 316.025 919.778 316.09C919.913 316.156 920.031 316.244 920.125 316.35C920.219 316.456 920.286 316.577 920.324 316.706L951.377 402.882C951.413 402.974 951.431 403.071 951.43 403.169V482.816C951.42 483.07 951.296 483.311 951.084 483.487C950.872 483.664 950.589 483.763 950.294 483.763Z" fill="#72B094" />
            <path d="M1029.06 616.943C1028.76 616.943 1028.47 616.84 1028.26 616.656C1028.05 616.472 1027.93 616.223 1027.93 615.963V380.939C1027.93 380.68 1028.05 380.43 1028.26 380.247C1028.47 380.063 1028.76 379.96 1029.06 379.96C1029.36 379.96 1029.65 380.063 1029.87 380.247C1030.08 380.43 1030.2 380.68 1030.2 380.939V615.963C1030.2 616.223 1030.08 616.472 1029.87 616.656C1029.65 616.84 1029.36 616.943 1029.06 616.943Z" fill="#72B094" />
            <path d="M1122.98 347.317H1122.83L934.623 326.981C934.325 326.949 934.054 326.816 933.87 326.611C933.685 326.407 933.602 326.148 933.639 325.891C933.678 325.634 933.834 325.4 934.073 325.241C934.311 325.082 934.612 325.011 934.911 325.042L1122.82 345.359L1278.21 290.246L1128.92 240.31L1002.06 259.183L821.432 306.534C821.147 306.602 820.842 306.571 820.582 306.447C820.323 306.324 820.13 306.118 820.044 305.874C819.958 305.63 819.986 305.366 820.122 305.14C820.259 304.913 820.492 304.741 820.773 304.661L1001.46 257.297L1001.61 257.264L1128.85 238.332C1129.05 238.303 1129.25 238.321 1129.45 238.384L1281.68 289.306C1281.89 289.378 1282.07 289.503 1282.2 289.666C1282.33 289.828 1282.4 290.02 1282.4 290.216C1282.4 290.413 1282.33 290.605 1282.2 290.767C1282.07 290.929 1281.89 291.055 1281.68 291.127L1123.39 347.272C1123.25 347.308 1123.12 347.323 1122.98 347.317Z" fill="#72B094" />
            <path d="M1122.98 404.116C1122.68 404.116 1122.39 404.013 1122.17 403.829C1121.96 403.645 1121.84 403.396 1121.84 403.136V346.796L1001.06 258.956C1000.83 258.788 1000.68 258.547 1000.66 258.288C1000.63 258.028 1000.73 257.771 1000.92 257.572C1001.12 257.372 1001.4 257.248 1001.7 257.226C1002 257.204 1002.3 257.286 1002.53 257.454L1123.71 345.588C1123.84 345.681 1123.94 345.796 1124.01 345.925C1124.08 346.055 1124.11 346.196 1124.11 346.339V403.136C1124.11 403.265 1124.08 403.392 1124.03 403.511C1123.97 403.63 1123.89 403.738 1123.78 403.829C1123.67 403.92 1123.55 403.992 1123.41 404.041C1123.27 404.09 1123.13 404.116 1122.98 404.116Z" fill="#72B094" />
            <path d="M1111.62 528.808C1111.31 528.808 1111.03 528.705 1110.81 528.522C1110.6 528.338 1110.48 528.089 1110.48 527.829V417.498C1110.48 417.356 1110.51 417.216 1110.58 417.087C1110.65 416.959 1110.75 416.845 1110.88 416.754C1111.01 416.661 1111.15 416.593 1111.31 416.555C1111.47 416.517 1111.64 416.509 1111.8 416.532L1454.14 465.496C1454.29 465.517 1454.44 465.564 1454.56 465.633C1454.69 465.702 1454.8 465.792 1454.89 465.898C1454.98 466.004 1455.04 466.124 1455.08 466.251C1455.11 466.378 1455.11 466.509 1455.09 466.638C1455.06 466.767 1455.01 466.89 1454.93 467C1454.85 467.111 1454.75 467.207 1454.62 467.283C1454.5 467.358 1454.36 467.413 1454.21 467.442C1454.07 467.471 1453.91 467.476 1453.76 467.454L1112.75 418.654V527.829C1112.75 528.089 1112.63 528.338 1112.42 528.522C1112.21 528.705 1111.92 528.808 1111.62 528.808Z" fill="#72B094" />
            <path d="M1111.62 418.478C1111.41 418.478 1111.22 418.431 1111.04 418.343C1110.87 418.256 1110.72 418.129 1110.63 417.978C1110.53 417.826 1110.48 417.655 1110.48 417.481C1110.48 417.308 1110.54 417.138 1110.65 416.989L1145.49 367.373C1145.6 367.212 1145.77 367.083 1145.96 367C1146.16 366.918 1146.38 366.887 1146.6 366.91L1454.85 400.858C1455 400.874 1455.15 400.915 1455.28 400.98C1455.41 401.044 1455.52 401.13 1455.62 401.232C1455.71 401.334 1455.78 401.452 1455.82 401.577C1455.86 401.702 1455.87 401.832 1455.85 401.961C1455.83 402.089 1455.78 402.214 1455.71 402.326C1455.63 402.439 1455.53 402.538 1455.41 402.617C1455.29 402.696 1455.16 402.755 1455.01 402.789C1454.87 402.823 1454.72 402.832 1454.57 402.816L1147.07 368.946L1112.61 418.021C1112.5 418.165 1112.36 418.282 1112.18 418.363C1112.01 418.443 1111.81 418.482 1111.62 418.478Z" fill="#72B094" />
            <path d="M1191 373.752C1190.7 373.752 1190.41 373.648 1190.19 373.465C1189.98 373.281 1189.86 373.032 1189.86 372.772V322.216C1189.86 321.956 1189.98 321.707 1190.19 321.524C1190.41 321.34 1190.7 321.237 1191 321.237C1191.3 321.237 1191.59 321.34 1191.8 321.524C1192.01 321.707 1192.13 321.956 1192.13 322.216V372.779C1192.13 373.038 1192.01 373.285 1191.8 373.467C1191.59 373.649 1191.3 373.752 1191 373.752Z" fill="#72B094" />
            <path d="M1222.19 331.65H1222.03L1190.83 327.733C1190.68 327.714 1190.54 327.67 1190.41 327.603C1190.28 327.537 1190.17 327.449 1190.08 327.344C1189.99 327.24 1189.92 327.121 1189.88 326.995C1189.85 326.869 1189.84 326.738 1189.86 326.61C1189.88 326.481 1189.93 326.358 1190.01 326.246C1190.09 326.134 1190.19 326.037 1190.31 325.959C1190.43 325.882 1190.57 325.826 1190.72 325.794C1190.86 325.762 1191.02 325.755 1191.16 325.774L1222.04 329.691L1322.26 292.851L1210.76 253.361L1185.93 259.184C1185.79 259.217 1185.64 259.226 1185.49 259.209C1185.34 259.193 1185.2 259.151 1185.07 259.087C1184.94 259.022 1184.82 258.937 1184.73 258.835C1184.64 258.733 1184.58 258.616 1184.54 258.492C1184.5 258.368 1184.49 258.238 1184.51 258.11C1184.53 257.983 1184.58 257.86 1184.65 257.748C1184.72 257.636 1184.82 257.539 1184.94 257.46C1185.06 257.382 1185.2 257.324 1185.34 257.291L1210.54 251.415C1210.79 251.357 1211.05 251.37 1211.3 251.454L1325.66 291.931C1325.87 292.005 1326.04 292.13 1326.17 292.291C1326.29 292.452 1326.36 292.641 1326.36 292.835C1326.36 293.028 1326.29 293.217 1326.17 293.378C1326.04 293.539 1325.87 293.665 1325.66 293.739L1222.66 331.604C1222.51 331.646 1222.35 331.662 1222.19 331.65Z" fill="#72B094" />
            <path d="M1302.48 372.126C1302.18 372.126 1301.89 372.023 1301.67 371.839C1301.46 371.655 1301.34 371.406 1301.34 371.147L1301.59 301.064C1301.59 300.804 1301.71 300.555 1301.92 300.371C1302.14 300.188 1302.43 300.084 1302.73 300.084C1303.03 300.086 1303.32 300.191 1303.53 300.376C1303.74 300.56 1303.86 300.81 1303.86 301.07L1303.61 371.153C1303.61 371.412 1303.49 371.659 1303.28 371.841C1303.06 372.024 1302.78 372.126 1302.48 372.126Z" fill="#72B094" />
            <path d="M1212.35 376.043C1212.2 376.042 1212.05 376.016 1211.91 375.966C1211.78 375.916 1211.65 375.843 1211.55 375.751C1211.44 375.66 1211.36 375.551 1211.3 375.432C1211.25 375.313 1211.22 375.186 1211.22 375.057L1211.59 329.469C1211.59 329.21 1211.71 328.963 1211.93 328.781C1212.14 328.598 1212.43 328.496 1212.73 328.496C1213.03 328.501 1213.32 328.608 1213.53 328.794C1213.74 328.979 1213.86 329.229 1213.86 329.488L1213.48 375.07C1213.48 375.329 1213.36 375.576 1213.15 375.758C1212.94 375.941 1212.65 376.043 1212.35 376.043Z" fill="#72B094" />
            <path d="M1336.63 389.752C1336.39 389.753 1336.15 389.687 1335.96 389.563C1335.76 389.438 1335.62 389.263 1335.55 389.063C1335.47 388.863 1335.48 388.648 1335.56 388.45C1335.64 388.252 1335.79 388.081 1335.99 387.963L1381.51 360.968L1327.02 359.721L1281.87 383.726C1281.61 383.863 1281.3 383.906 1281.01 383.846C1280.71 383.786 1280.46 383.628 1280.3 383.407C1280.14 383.185 1280.09 382.918 1280.16 382.665C1280.23 382.411 1280.41 382.192 1280.67 382.055L1326.11 357.9C1326.3 357.798 1326.52 357.748 1326.74 357.756L1385.06 359.062C1385.3 359.067 1385.54 359.137 1385.73 359.263C1385.92 359.389 1386.06 359.564 1386.13 359.763C1386.19 359.962 1386.19 360.175 1386.11 360.37C1386.03 360.566 1385.87 360.734 1385.68 360.851L1337.28 389.576C1337.09 389.69 1336.87 389.751 1336.63 389.752Z" fill="#72B094" />
            <path d="M1443.96 401.504C1443.8 401.505 1443.64 401.474 1443.49 401.412L1384.55 377.91C1384.36 377.832 1384.19 377.707 1384.07 377.55C1383.96 377.392 1383.89 377.209 1383.9 377.022V360.048C1383.9 359.788 1384.02 359.539 1384.23 359.356C1384.44 359.172 1384.73 359.069 1385.03 359.069C1385.33 359.069 1385.62 359.172 1385.83 359.356C1386.05 359.539 1386.17 359.788 1386.17 360.048V376.369L1444.44 399.617C1444.68 399.711 1444.87 399.871 1444.98 400.071C1445.09 400.271 1445.13 400.498 1445.07 400.716C1445.02 400.933 1444.88 401.128 1444.67 401.268C1444.47 401.407 1444.22 401.484 1443.96 401.484V401.504Z" fill="#72B094" />
            <path d="M984.376 699.854C984.074 699.854 983.785 699.751 983.572 699.567C983.359 699.383 983.239 699.134 983.239 698.874V489.468C972.159 487.633 865.663 469.98 859.952 469.06C859.803 469.036 859.661 468.987 859.534 468.915C859.407 468.844 859.297 468.752 859.211 468.644C859.125 468.536 859.065 468.415 859.034 468.287C859.002 468.159 859.001 468.026 859.028 467.898C859.056 467.769 859.113 467.647 859.196 467.537C859.279 467.427 859.386 467.333 859.511 467.259C859.636 467.185 859.777 467.133 859.926 467.106C860.074 467.079 860.227 467.077 860.377 467.101C866.367 468.068 983.406 487.47 984.588 487.666C984.848 487.707 985.083 487.826 985.252 488.001C985.42 488.177 985.512 488.398 985.512 488.626V698.842C985.517 698.973 985.491 699.104 985.436 699.227C985.381 699.349 985.299 699.461 985.193 699.555C985.087 699.65 984.96 699.725 984.819 699.776C984.679 699.827 984.528 699.854 984.376 699.854Z" fill="#72B094" />
            <path d="M496.619 594.094C496.485 594.094 496.352 594.074 496.225 594.035L437.149 575.102C436.886 575.003 436.677 574.822 436.563 574.594C436.449 574.367 436.439 574.112 436.535 573.878C436.631 573.645 436.826 573.452 437.08 573.338C437.335 573.223 437.63 573.196 437.907 573.261L495.468 591.704V498.452C495.468 498.192 495.588 497.943 495.801 497.759C496.014 497.576 496.303 497.473 496.604 497.473C496.905 497.473 497.194 497.576 497.407 497.759C497.62 497.943 497.74 498.192 497.74 498.452V593.114C497.74 593.374 497.62 593.623 497.407 593.807C497.194 593.991 496.905 594.094 496.604 594.094H496.619Z" fill="#72B094" />
            <path d="M250.423 478.155C250.283 478.152 250.145 478.13 250.014 478.089L164.505 449.75C164.287 449.677 164.101 449.548 163.971 449.381C163.841 449.214 163.774 449.016 163.779 448.815C163.784 448.614 163.86 448.419 163.998 448.257C164.136 448.095 164.329 447.973 164.55 447.908L288.004 412.002C288.27 411.914 288.564 411.914 288.83 412.002L332.001 428.565C332.14 428.615 332.266 428.689 332.372 428.782C332.478 428.875 332.561 428.986 332.617 429.107C332.672 429.228 332.699 429.358 332.696 429.488C332.693 429.618 332.66 429.747 332.598 429.866C332.537 429.985 332.448 430.092 332.338 430.181C332.228 430.271 332.098 430.34 331.956 430.385C331.815 430.431 331.664 430.451 331.513 430.446C331.362 430.44 331.213 430.409 331.077 430.353L288.315 413.947L168.231 448.894L250.847 476.262C251.094 476.344 251.301 476.498 251.431 476.698C251.56 476.897 251.604 477.13 251.556 477.355C251.507 477.58 251.369 477.783 251.165 477.929C250.96 478.075 250.703 478.155 250.438 478.155H250.423Z" fill="#72B094" />
            <path d="M1366.59 393.017C1366.36 393.019 1366.13 392.959 1365.94 392.847C1365.75 392.734 1365.6 392.573 1365.52 392.386C1365.44 392.199 1365.42 391.995 1365.48 391.8C1365.53 391.606 1365.66 391.432 1365.83 391.3L1384.27 377.59C1384.39 377.505 1384.52 377.439 1384.66 377.397C1384.8 377.355 1384.95 377.338 1385.1 377.346C1385.25 377.354 1385.4 377.388 1385.54 377.445C1385.67 377.502 1385.79 377.581 1385.89 377.678C1385.99 377.776 1386.07 377.889 1386.12 378.012C1386.16 378.134 1386.19 378.264 1386.18 378.394C1386.17 378.523 1386.13 378.65 1386.06 378.766C1385.99 378.882 1385.9 378.987 1385.79 379.072L1367.35 392.782C1367.14 392.935 1366.87 393.019 1366.59 393.017Z" fill="#72B094" />
            <path d="M1255.52 267.671C1255.22 267.671 1254.93 267.567 1254.72 267.384C1254.5 267.2 1254.38 266.951 1254.38 266.691V156.55L1212.21 67.2799L1156.68 157.908L1156.76 248.164C1156.76 248.423 1156.64 248.672 1156.42 248.856C1156.21 249.04 1155.92 249.143 1155.62 249.143C1155.32 249.143 1155.03 249.04 1154.82 248.856C1154.6 248.672 1154.48 248.423 1154.48 248.164L1154.41 157.666C1154.41 157.507 1154.45 157.351 1154.54 157.209L1211.34 64.5053C1211.44 64.3392 1211.59 64.2014 1211.78 64.1088C1211.97 64.0161 1212.18 63.9724 1212.4 63.983C1212.62 63.9928 1212.83 64.0558 1213 64.1645C1213.18 64.2731 1213.32 64.4231 1213.4 64.5967L1256.57 155.995C1256.63 156.111 1256.65 156.235 1256.66 156.361V266.691C1256.66 266.951 1256.54 267.2 1256.32 267.384C1256.11 267.567 1255.82 267.671 1255.52 267.671Z" fill="#72B094" />
            <path d="M1404.72 385.836C1404.42 385.836 1404.13 385.733 1403.92 385.549C1403.71 385.366 1403.59 385.116 1403.59 384.857V119.345L1347.09 3.37326L1282.41 119.371V275.179C1282.41 275.438 1282.29 275.688 1282.07 275.871C1281.86 276.055 1281.57 276.158 1281.27 276.158C1280.97 276.158 1280.68 276.055 1280.47 275.871C1280.25 275.688 1280.13 275.438 1280.13 275.179V119.149C1280.13 119.002 1280.17 118.857 1280.25 118.724L1346.14 0.559496C1346.23 0.388883 1346.38 0.245558 1346.57 0.147017C1346.76 0.0484755 1346.97 -0.00101821 1347.19 0.00457747C1347.41 0.010843 1347.62 0.0707161 1347.8 0.177135C1347.98 0.283554 1348.12 0.43208 1348.21 0.605195L1405.77 118.77C1405.83 118.89 1405.86 119.018 1405.86 119.149V384.857C1405.86 385.116 1405.74 385.366 1405.53 385.549C1405.31 385.733 1405.02 385.836 1404.72 385.836Z" fill="#72B094" />
            <path d="M1311.49 65.9417H1212.35C1212.05 65.9417 1211.76 65.8385 1211.54 65.6549C1211.33 65.4712 1211.21 65.2221 1211.21 64.9624C1211.21 64.7027 1211.33 64.4536 1211.54 64.27C1211.76 64.0863 1212.05 63.9832 1212.35 63.9832H1311.49C1311.79 63.9832 1312.08 64.0863 1312.29 64.27C1312.51 64.4536 1312.62 64.7027 1312.62 64.9624C1312.62 65.2221 1312.51 65.4712 1312.29 65.6549C1312.08 65.8385 1311.79 65.9417 1311.49 65.9417Z" fill="#72B094" />
            <path d="M1281.27 157.34H1255.52C1255.22 157.34 1254.93 157.237 1254.72 157.053C1254.5 156.869 1254.38 156.62 1254.38 156.361C1254.38 156.101 1254.5 155.852 1254.72 155.668C1254.93 155.485 1255.22 155.381 1255.52 155.381H1281.27C1281.57 155.381 1281.86 155.485 1282.07 155.668C1282.29 155.852 1282.41 156.101 1282.41 156.361C1282.41 156.62 1282.29 156.869 1282.07 157.053C1281.86 157.237 1281.57 157.34 1281.27 157.34Z" fill="#72B094" />
            <path d="M1453.95 10.4498H1453.85L1347.06 1.96279C1346.91 1.95079 1346.76 1.91357 1346.63 1.85327C1346.5 1.79297 1346.38 1.71076 1346.28 1.61134C1346.19 1.51192 1346.12 1.39724 1346.07 1.27383C1346.03 1.15043 1346.01 1.02072 1346.03 0.892125C1346.04 0.763526 1346.08 0.63855 1346.15 0.524333C1346.22 0.410116 1346.32 0.308895 1346.43 0.226448C1346.55 0.144002 1346.68 0.0819456 1346.83 0.0438217C1346.97 0.00569789 1347.12 -0.00774653 1347.27 0.00425608L1454.06 8.49124C1454.36 8.50336 1454.64 8.61816 1454.85 8.81038C1455.05 9.00259 1455.16 9.25649 1455.14 9.51621C1455.13 9.77592 1455 10.0202 1454.77 10.1953C1454.55 10.3703 1454.26 10.4619 1453.95 10.4498Z" fill="#72B094" />
            <path d="M1454.71 120.128H1404.72C1404.42 120.128 1404.13 120.025 1403.92 119.841C1403.71 119.657 1403.59 119.408 1403.59 119.148C1403.59 118.889 1403.71 118.64 1403.92 118.456C1404.13 118.272 1404.42 118.169 1404.72 118.169H1454.71C1455.01 118.169 1455.3 118.272 1455.51 118.456C1455.73 118.64 1455.85 118.889 1455.85 119.148C1455.85 119.408 1455.73 119.657 1455.51 119.841C1455.3 120.025 1455.01 120.128 1454.71 120.128Z" fill="#72B094" />
            <path d="M1.28906 637.834C0.987756 637.867 0.683629 637.795 0.443586 637.635C0.203543 637.474 0.0472475 637.238 0.00908197 636.979C-0.0290835 636.719 0.0540077 636.457 0.240077 636.25C0.426145 636.043 0.69995 635.908 1.00126 635.875L472.095 530.114C472.241 530.076 472.395 530.064 472.547 530.078C472.698 530.093 472.845 530.133 472.979 530.197C473.112 530.262 473.229 530.349 473.322 530.453C473.416 530.557 473.484 530.676 473.522 530.804C473.561 530.931 473.57 531.064 473.548 531.194C473.525 531.324 473.473 531.449 473.393 531.562C473.314 531.674 473.209 531.772 473.085 531.848C472.96 531.925 472.82 531.979 472.67 532.008L1.57687 637.834C1.48147 637.846 1.38446 637.846 1.28906 637.834Z" fill="#72B094" />
            <path d="M437.543 712.911C437.242 712.911 436.953 712.808 436.74 712.624C436.526 712.441 436.407 712.192 436.407 711.932L436.361 538.928C436.361 538.668 436.481 538.419 436.694 538.236C436.907 538.052 437.196 537.949 437.497 537.949C437.799 537.949 438.088 538.052 438.301 538.236C438.514 538.419 438.633 538.668 438.633 538.928L438.679 711.932C438.679 712.192 438.559 712.441 438.346 712.624C438.133 712.808 437.844 712.911 437.543 712.911Z" fill="#72B094" />
            <path d="M507.222 646.321C507.08 646.322 506.938 646.3 506.805 646.256L437.126 622.753C436.845 622.658 436.619 622.471 436.498 622.232C436.378 621.993 436.372 621.723 436.482 621.48C436.593 621.238 436.81 621.043 437.087 620.939C437.364 620.835 437.678 620.83 437.959 620.925L507.23 644.291L983.996 487.745C984.138 487.69 984.291 487.66 984.446 487.658C984.601 487.656 984.755 487.681 984.899 487.732C985.042 487.783 985.172 487.859 985.28 487.955C985.388 488.051 985.473 488.165 985.528 488.29C985.583 488.415 985.607 488.549 985.6 488.683C985.593 488.816 985.554 488.947 985.485 489.068C985.417 489.188 985.321 489.295 985.203 489.382C985.085 489.469 984.947 489.534 984.799 489.573L507.646 646.256C507.512 646.303 507.367 646.325 507.222 646.321Z" fill="#72B094" />
            <path d="M507.222 712.912C506.921 712.912 506.632 712.808 506.419 712.625C506.206 712.441 506.086 712.192 506.086 711.932V645.342C506.086 645.082 506.206 644.833 506.419 644.65C506.632 644.466 506.921 644.363 507.222 644.363C507.523 644.363 507.812 644.466 508.025 644.65C508.238 644.833 508.358 645.082 508.358 645.342V711.932C508.358 712.192 508.238 712.441 508.025 712.625C507.812 712.808 507.523 712.912 507.222 712.912Z" fill="#72B094" />
            <path d="M1454.71 684.186C1454.62 684.198 1454.52 684.198 1454.42 684.186L1050.74 594.093C1050.56 594.046 1050.4 593.962 1050.26 593.848C1050.13 593.734 1050.04 593.594 1049.98 593.44C1049.92 593.283 1049.91 593.115 1049.95 592.953C1049.98 592.791 1050.07 592.64 1050.19 592.513L1148.65 491.322C1148.79 491.186 1148.96 491.083 1149.16 491.025C1149.35 490.968 1149.56 490.958 1149.77 490.996L1454.99 549.099C1455.14 549.124 1455.28 549.174 1455.41 549.247C1455.53 549.319 1455.64 549.413 1455.73 549.522C1455.81 549.631 1455.87 549.753 1455.89 549.882C1455.92 550.01 1455.92 550.142 1455.89 550.27C1455.85 550.397 1455.79 550.518 1455.71 550.625C1455.62 550.732 1455.51 550.822 1455.38 550.891C1455.25 550.961 1455.11 551.007 1454.96 551.028C1454.81 551.048 1454.65 551.043 1454.51 551.012L1149.96 493.033L1053.06 592.552L1455 682.26C1455.15 682.276 1455.29 682.318 1455.42 682.382C1455.55 682.446 1455.67 682.532 1455.76 682.634C1455.85 682.737 1455.92 682.854 1455.96 682.979C1456 683.104 1456.01 683.235 1455.99 683.363C1455.97 683.492 1455.92 683.616 1455.85 683.728C1455.78 683.841 1455.68 683.94 1455.56 684.019C1455.44 684.099 1455.3 684.157 1455.16 684.191C1455.01 684.225 1454.86 684.235 1454.71 684.218V684.186Z" fill="#72B094" />
            <path d="M1097.98 699.854C1097.68 699.854 1097.4 699.752 1097.18 699.57C1096.97 699.387 1096.85 699.14 1096.85 698.881L1096.51 603.527C1096.51 603.266 1096.63 603.017 1096.85 602.832C1097.06 602.647 1097.35 602.543 1097.65 602.541C1097.95 602.541 1098.24 602.644 1098.45 602.828C1098.67 603.011 1098.79 603.261 1098.79 603.52L1099.12 698.875C1099.12 699.135 1099 699.384 1098.79 699.567C1098.57 699.751 1098.28 699.854 1097.98 699.854Z" fill="#72B094" />
            <path d="M984.376 637.834C984.122 637.834 983.875 637.76 983.675 637.624C983.476 637.489 983.335 637.3 983.275 637.087C983.214 636.874 983.239 636.65 983.343 636.451C983.448 636.251 983.628 636.088 983.853 635.987L1070.95 596.816C1071.22 596.696 1071.53 596.672 1071.82 596.749C1072.11 596.827 1072.35 597 1072.49 597.231C1072.63 597.461 1072.65 597.73 1072.56 597.977C1072.47 598.225 1072.27 598.432 1072 598.553L984.906 637.723C984.743 637.799 984.56 637.837 984.376 637.834Z" fill="#72B094" />
            <path d="M1097.65 689.735C1097.56 689.748 1097.47 689.748 1097.38 689.735L984.096 665.097C983.804 665.032 983.553 664.871 983.399 664.648C983.245 664.424 983.2 664.157 983.274 663.905C983.349 663.653 983.536 663.437 983.795 663.304C984.054 663.171 984.364 663.133 984.657 663.197L1097.93 687.842C1098.23 687.874 1098.51 688.008 1098.69 688.214C1098.88 688.42 1098.96 688.682 1098.93 688.942C1098.89 689.201 1098.73 689.438 1098.5 689.599C1098.26 689.76 1097.95 689.832 1097.65 689.8V689.735Z" fill="#72B094" />
        </svg>
    )
}








