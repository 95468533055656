import React from "react"
import "../styles/hero.css"

export default function Hero(props) {
    if (props.orientation === "vertical") {
        return (
            <div className="hero">
                <div className="heroContainer">
                    <div className="heroTitle">{props.title}</div>
                    <div className="heroText">{props.text}</div>
                </div>
                <div className="heroImageContainer">
                    <img src={props.image} alt="" className="heroImage" />
                </div>
            </div>
        )
    }
    else if (props.orientation === "horizontal") {
        return (
            <div className="hero hero__mb250">
                <div className="heroContainer">
                    <div className="heroImageContainer heroImageContainer__horizontal">
                        <img src={props.image} alt="" className="heroImage" />
                    </div>
                    <div className="heroTitle">{props.title}</div>
                    <div className="heroText heroText__horizontal">{props.text}</div>
                </div>
            </div>
        )
    }
}