import React from "react"
import "../styles/ctaBanner.css"
import Cta from "../images/cta.js"

export default function BasicBanner(props) {
    return (
        <div className="ctaBanner">
            <div className="ctaBannerImage"><Cta></Cta></div>
            <div className="ctaBannerContainer">
                <div className="ctaBannerTitle">{props.bigBanner.title}</div>
                <a href="/contact" className="ctaBannerButton">{props.bigBanner.buttonText}</a>
            </div>
        </div>
    )
}